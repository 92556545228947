import {createRoot} from 'react-dom/client';
import {HashRouter, Route, Routes} from 'react-router-dom';

import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import {AppConfirmDialog, AppToast, DefaultConf, ScrollToTop} from '@iamsoftware/react-hooks';

import './assets/layout/layout.scss';
import '@iamsoftware/react-hooks/assets/style.scss';
import './App.scss';

import {AppLoading} from './AppLoading';

import App from './app';
import Login from './pages/login';
import InvoiceInfo from './pages/invoice-info';
import KiemTraHoaDon from './pages/kiem-tra-hoa-don';

DefaultConf.DataTable.ActionColumnHeader = 'Chức năng';
DefaultConf.Form.FormatCalendar = value => {
  if (value) {
    return new Date(value).getTime();
  }
  return value;
};

document.documentElement.style.fontSize = '14px';

const root = createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <HashRouter>
    <ScrollToTop>
      <Routes>
        <Route path="login" element={<Login/>}/>
        <Route path="invoice-info" element={<InvoiceInfo from="invoice"/>}/>
        <Route path="xml-info" element={<InvoiceInfo from="xml"/>}/>
        <Route path="kiem-tra-hoa-don" element={<KiemTraHoaDon/>}/>
        <Route path="*" element={<KiemTraHoaDon/>}/>
      </Routes>
      <AppLoading/>
      <AppToast/>
      <AppConfirmDialog/>
    </ScrollToTop>
  </HashRouter>
);
