import {Service} from './Service';

export class TaxCodeService extends Service {

  static entity = 'org-by-tax-code';

  static get(taxCode: string): Promise<any> {
    return this.axios.get(`${this.entity}/${taxCode}`).then(res => res.data);
  }

}
