import {useEffect, useRef, useState} from 'react';

import {Dialog} from 'primereact/dialog';
import {Messages} from 'primereact/messages';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';

import {HDDTService} from '../kiem-tra-hoa-don/HDDTService';
import {InvoicesService} from '../../app/hoa-don/InvoicesService';

export const InvoiceStatus = ({display, setDisplay, invoiceInfo}) => {

  const msgs = useRef(null);

  const [captchaContent, setCaptchaContent] = useState(null);
  const [ckey, setCkey] = useState(null);
  const [cvalue, setCvalue] = useState('');

  const [submitting, setSubmitting] = useState(false);

  const [tthai, setTthai] = useState(null);
  const [ttxly, setTtxly] = useState(null);

  useEffect(() => {
    if (display && invoiceInfo) {
      loadCaptcha();
    }
  }, [display, invoiceInfo]);

  const loadCaptcha = () => {
    HDDTService.getCaptcha().then(({key, content}) => {
      setCkey(key);
      setCaptchaContent(content);
      setCvalue('');
    });
  }

  const resetResult = () => {
    msgs.current.clear();
    setTthai(null);
    setTtxly(null);
  }

  const handleSubmit = (event) => {
    resetResult();
    if (!submitting) {
      setSubmitting(true);

      HDDTService.queryGuestInvoices({
        khmshdon: invoiceInfo.khmshdon,
        hdon: invoiceInfo.hdon,
        nbmst: invoiceInfo.nbmst,
        khhdon: invoiceInfo.khhdon,
        shdon: parseInt(invoiceInfo.shdon),
        tgtttbso: parseFloat(invoiceInfo.tgtttbso),
        tdlap: new Date(invoiceInfo.tdlap).toISOString(),
        cvalue,
        ckey
      }).then(result => {
        if (result.tthai) {
          InvoicesService.tthais.forEach(t => {
            if (t.value === result.tthai) {
              setTthai(t.label);
            }
          });
          InvoicesService.ttxlys.forEach(t => {
            if (t.value === result.ttxly) {
              setTtxly(t.label);
            }
          });
        } else if (result.message) {
          msgs.current.show({severity: 'error', detail: result.message, sticky: true});
        } else {
          msgs.current.show({severity: 'error', detail: 'Không tồn tại hóa đơn có thông tin trùng khớp với các thông tin tổ chức, cá nhân tìm kiếm.', sticky: true});
        }
      }).catch(error => {
        if (error && error.message) {
          msgs.current.show({severity: 'error', detail: error.message, sticky: true});
        }
      }).finally(() => {
        setSubmitting(false);
        loadCaptcha();
      });
    }
    event.preventDefault();
  }

  return (
    <Dialog header="Trạng thái hóa đơn" visible={display} style={{width: '40rem'}} position="top" onHide={() => setDisplay(false)}>
      <form onSubmit={handleSubmit}>
        <div className="p-fluid">
          <div className="field grid">
            <div className="col-12">
              <div className="grid">
                <div className="col-fixed" style={{width: '200px'}}>
                  <label>&nbsp;</label>
                  <div className="captcha" dangerouslySetInnerHTML={{__html: captchaContent}}></div>
                </div>
                <div className="col-fixed pl-0" style={{width: '30px'}}>
                  <label>&nbsp;</label>
                  <i className="pi pi-refresh pointer mt-2" style={{fontSize: 20}} onClick={loadCaptcha}></i>
                </div>
                <div className="col">
                  <label>&nbsp;</label>
                  <InputText value={cvalue} onChange={e => setCvalue(e.target.value)} required placeholder="Nhập mã captcha" style={{height: 40}}/>
                </div>
              </div>
            </div>
          </div>
          {tthai &&
						<div className="card-container blue-container overflow-hidden">
							<p>Tồn tại hóa đơn có thông tin trùng khớp với các thông tin tổ chức, cá nhân tìm kiếm.</p>
							<p>Trạng thái hoá đơn: {tthai}</p>
							<p>Trạng thái xử lý hoá đơn: {ttxly}</p>
						</div>
          }
          <Messages ref={msgs}/>
        </div>
        <div className="grid mt-1">
          <div className="col-12 text-center">
            <Button label="Tra cứu" icon="pi pi-search" className="p-button-outlined p-button-sm mr-2" loading={submitting}/>
          </div>
        </div>
      </form>
    </Dialog>
  );
}