import {useState} from 'react';

import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {Password} from 'primereact/password';
import {Checkbox} from 'primereact/checkbox';
import {ToastService} from '@iamsoftware/react-hooks';

import {UsersService} from '../../service/UsersService';

export default function Login() {

  const [submitting, setSubmitting] = useState(false);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [rememberMe, setRememberMe] = useState(false);

  const [forgotPassword, setForgotPassword] = useState(false);

  const handleSubmit = event => {
    if (!submitting) {
      setSubmitting(true);

      UsersService.login(username, password).then(() => {
        window.location.hash = '/';
      }).finally(() => {
        setSubmitting(false);
      });
    }
    event.preventDefault();
  }

  const resetPassword = event => {
    if (!submitting) {
      setSubmitting(true);

      UsersService.resetPassword(username, email).then(() => {
        ToastService.success('Hoàn thành yêu cầu đặt lại mật khẩu. Mật khẩu mới đã được gửi đến email cuả quý khách!');
        setForgotPassword(false);
      }).finally(() => {
        setSubmitting(false);
      });
    }
    event.preventDefault();
  }

  return (
    <div className="layout-wrapper layout-overlay layout-theme-light" style={{backgroundColor: '#fff', height: '100vh'}}>

      <div className="layout-main p-3 h-full">
        <div className="grid h-full">
          <div className="sm:hidden md:block lg:block xl:block md:col-8 lg:col-8 xl:col-7" style={{backgroundImage: 'url(/assets/layout/images/bg-4.png)', backgroundSize: 'contain', backgroundRepeat: 'no-repeat'}}>
            <div className="p-5">
              <div className="mt-2">
                <img src={'/m-invoice/logominvoice_ngang-02.png'} alt="logo" width="220"/>
              </div>
            </div>
          </div>
          <div className="col-12 md:col-4 lg:col-4 xl:col-5 flex justify-content-center align-content-center flex-wrap">
            <div className="card surface-ground" style={{width: '420px'}}>
              <div className="text-center">
                <img src={'/m-invoice/logominvoice_dung-03.png'} alt="logo" width="220"/>
              </div>

              <div className="text-center mb-5">
                <span className="text-600 font-medium line-height-3">Quản lý hóa đơn đầu vào</span>
              </div>

              {!forgotPassword &&
								<form onSubmit={handleSubmit} className="p-fluid p-2">
									<label htmlFor="username" className="block text-900 font-medium mb-2">Tài khoản</label>
									<InputText id="username" type="text" className="w-full mb-3" value={username} onChange={e => setUsername(e.target.value)} required/>

									<label htmlFor="password" className="block text-900 font-medium mb-2">Mật khẩu</label>
									<Password id="password" className="w-full mb-3" value={password} onChange={e => setPassword(e.target.value)} toggleMask feedback={false} required/>

									<div className="flex align-items-center justify-content-between mb-6">
										<div className="flex align-items-center">
											<Checkbox inputId="rememberMe" className="mr-2" onChange={e => setRememberMe(e.checked)} checked={rememberMe}/>
											<label htmlFor="rememberMe">Nhớ tài khoản</label>
										</div>
										<span className="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer" onClick={() => setForgotPassword(true)}>Quên mật khẩu?</span>
									</div>

									<Button label="Đăng nhập" icon="pi pi-user" className="w-full" loading={submitting}/>

									<div className="text-center mt-3">
										Bạn muốn dùng thử? <a href="https://minvoice.vn/dang-ky-su-dung/" target="_blank" rel="noreferrer" className="font-medium no-underline ml-2 text-red-500">Đăng ký</a>
									</div>
								</form>
              }
              {forgotPassword &&
								<form onSubmit={resetPassword} className="p-fluid p-2">
									<label htmlFor="username" className="block text-900 font-medium mb-2">Tài khoản</label>
									<InputText id="username" type="text" className="w-full mb-3" value={username} onChange={e => setUsername(e.target.value)} required/>

									<label htmlFor="email" className="block text-900 font-medium mb-2">Email</label>
									<InputText id="email" type="text" className="w-full mb-3" value={email} onChange={e => setEmail(e.target.value)} required/>

									<Button label="Lấy lại mật khẩu" className="w-full mt-3" loading={submitting}/>

									<div className="text-center mt-5 pointer" onClick={() => setForgotPassword(false)}>
										<i className="pi pi-arrow-left mr-3"></i>Quay lại đăng nhập
									</div>
								</form>
              }

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}